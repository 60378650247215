@import 'styles/AdminLTE.css';
@import 'styles/loading-bar.css';
@import 'styles/json-editor.css';
@import 'styles/ui-grid-draggable-rows.css';
@import 'styles/main.css';

/*
    Skin AESG
    ---------
*/
/* Variables */
$baseColor: #4D4D58; 
$logoHover:darken($baseColor,10);

$menuColor: #f6f6f6;
$menuActiveColor: #ffffff;
$menuFontColor: #f9f9f9;
$leftSidebarBackground:#f4f4f4;
$backgroundContentHeader: #fbfbfb;

$userPanelFontColor:#555555;
$userPanelBorderColor:#dfdfdf;


/* skin-aesg login */
.lockscreen{
    // background:$baseColor;
}
.form-box .header {
    background:#3b3b3b;
}
header.header .logo {
    font-family: sans-serif;
}



/* skin-aesg navbar */
.skin-aesg .navbar {
  background-color: $baseColor;
}
.skin-aesg .navbar .nav a {
  color: rgba(255, 255, 255, 0.8);
}
.skin-aesg .navbar .nav > li > a:hover,
.skin-aesg .navbar .nav > li > a:active,
.skin-aesg .navbar .nav > li > a:focus,
.skin-aesg .navbar .nav .open > a,
.skin-aesg .navbar .nav .open > a:hover,
.skin-aesg .navbar .nav .open > a:focus {
  background: rgba(0, 0, 0, 0.1);
  color: $menuColor;
}
.skin-aesg .navbar .navbar-right > .nav {
  margin-right: 10px;
}
.skin-aesg .navbar .sidebar-toggle .icon-bar {
  background: rgba(255, 255, 255, 0.8);
}
.skin-aesg .navbar .sidebar-toggle:hover .icon-bar {
  background: $menuColor !important;
}
/* skin-aesg logo */
.skin-aesg .logo {
  background-color: $baseColor;
  color: $menuFontColor;
}
.skin-aesg .logo > a {
  color: $menuFontColor;
}
.skin-aesg .logo:hover {
  background: $logoHover;
}
/* skin-aesg content header */
.skin-aesg .right-side > .content-header {
  background: $backgroundContentHeader;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
/* Skin-blue user panel */
.skin-aesg .user-panel > .image > img {
  border: 1px solid $userPanelBorderColor;
}
.skin-aesg .user-panel > .info,
.skin-aesg .user-panel > .info > a {
  color: $userPanelFontColor;
}
/* skin-aesg sidebar */
.skin-aesg .sidebar {
  border-bottom: 1px solid #fff;
}
.skin-aesg .sidebar > .sidebar-menu > li {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #dbdbdb;
}
.skin-aesg .sidebar > .sidebar-menu > li:first-of-type {
  border-top: 1px solid #dbdbdb;
}
.skin-aesg .sidebar > .sidebar-menu > li:first-of-type > a {
  border-top: 1px solid #fff;
}
.skin-aesg .sidebar > .sidebar-menu > li > a {
  margin-right: 1px;
}
.skin-aesg .sidebar > .sidebar-menu > li > a:hover,
.skin-aesg .sidebar > .sidebar-menu > li.active > a {
  color: #222;
  background: $menuFontColor;
}
.skin-aesg .sidebar > .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: $menuFontColor;
}
.skin-aesg .left-side {
  background: $leftSidebarBackground;
  -webkit-box-shadow: inset -3px 0px 8px -4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset -3px 0px 8px -4px rgba(0, 0, 0, 0.1);
  box-shadow: inset -3px 0px 8px -4px rgba(0, 0, 0, 0.07);
}
.skin-aesg .sidebar a {
  color: $userPanelFontColor;
}
.skin-aesg .sidebar a:hover {
  text-decoration: none;
}
.skin-aesg .treeview-menu > li > a {
  color: #777;
}
.skin-aesg .treeview-menu > li.active > a,
.skin-aesg .treeview-menu > li > a:hover {
  color: #111;
}
.skin-aesg .sidebar-form {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #dbdbdb;
  margin: 10px 10px;
}
.skin-aesg .sidebar-form input[type="text"],
.skin-aesg .sidebar-form .btn {
  box-shadow: none;
  background-color: #fafafa;
  border: 1px solid #fafafa;
  height: 35px;
}
.skin-aesg .sidebar-form input[type="text"] {
  color: #666;
  -webkit-border-top-left-radius: 2px !important;
  -webkit-border-top-right-radius: 0 !important;
  -webkit-border-bottom-right-radius: 0 !important;
  -webkit-border-bottom-left-radius: 2px !important;
  -moz-border-radius-topleft: 2px !important;
  -moz-border-radius-topright: 0 !important;
  -moz-border-radius-bottomright: 0 !important;
  -moz-border-radius-bottomleft: 2px !important;
  border-top-left-radius: 2px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 2px !important;
}
.skin-aesg .sidebar-form input[type="text"]:focus,
.skin-aesg .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666;
}
.skin-aesg .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff;
}
.skin-aesg .sidebar-form .btn {
  color: #999;
  -webkit-border-top-left-radius: 0 !important;
  -webkit-border-top-right-radius: 2px !important;
  -webkit-border-bottom-right-radius: 2px !important;
  -webkit-border-bottom-left-radius: 0 !important;
  -moz-border-radius-topleft: 0 !important;
  -moz-border-radius-topright: 2px !important;
  -moz-border-radius-bottomright: 2px !important;
  -moz-border-radius-bottomleft: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 0 !important;
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header.bg-sylva {
    background-color: $baseColor;
}
.btn.btn-primary {
  background-color: $baseColor;
  border-color: darken($baseColor,10);
}
.btn.btn-primary:hover {
  background-color: darken($baseColor,10);
  border-color: darken($baseColor,10);
}

.nav-tabs-custom {
  margin-bottom: 0px;
}
.nav-tabs-custom > .nav-tabs {
}
.nav-tabs-custom > .tab-content {
  padding:0;
}
.nav-tabs-custom > .nav-tabs > li.active {
    border-top-color: $baseColor;
}

.skin-aesg .sidebar > .sidebar-menu > li > a:hover, .skin-aesg .sidebar > .sidebar-menu > li.active > a {
    color: #222;
    background: $menuActiveColor;
}

.quickselect.ui-select-bootstrap .ui-select-choices-row.active>span {
    background-color: $baseColor;
}

.sidebar-quickselect{
  margin: auto;
  padding: 5px;
  margin-top:7px;
  margin-bottom:7px;
}

.grid.project-components {
  border:none;
}
.ui-grid-viewport {
  overflow:auto !important;
}
.form-control:focus {
    border-color: lighten($baseColor,20) !important;
    box-shadow: none;
}
.content {
  overflow: visible;
}


/* formly - file upload */
.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}


.showDownloadInGrid {
  overflow:visible;
}
.showDownloadInGrid > .ui-grid-cell-contents {
  overflow:visible;
}
.grid {
  height: 600px;
}

.repeatsection {
  border: solid 2px #DADADA;
}